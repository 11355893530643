import React from 'react'
import 'minireset.css'
import GlobalStyles from '../styles/GlobalStyles'
import PageMeta from '../components/PageMeta'
//import 'normalize.css'
//import 'modern-normalize/modern-normalize.css'
//import Typography from '../styles/Typography' --> now using Typography.js which implements normalize. See gatsby-config.

const Layout = ({ children }) => {
  return (
    <>
      <PageMeta />
      <GlobalStyles />
      {children}
    </>
  )
}
export default Layout
