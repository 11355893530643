import React, { useState } from 'react'

const NavContext = React.createContext()

export function NavContextProvider({ children }) {
  const [region, setRegion] = useState('GBL')
  const [section, setSection] = useState('')
  const [isMobileOpen, setMobileOpen] = useState(false)
  return (
    <NavContext.Provider
      value={{
        region,
        setRegion,
        section,
        setSection,
        isMobileOpen,
        setMobileOpen
      }}
    >
      {children}
    </NavContext.Provider>
  )
}

export default NavContext
