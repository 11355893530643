import React from 'react'
import Layout from './src/components/Layout'
import { NavContextProvider } from './src/components/NavContext'

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
  return <NavContextProvider>{element}</NavContextProvider>
}
