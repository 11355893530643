//import { css } from '@emotion/react'
import { createGlobalStyle } from 'styled-components'
import { MediaQueries as Mq } from './MediaQueries'

const GlobalStyles = createGlobalStyle`
  :root {
    --darkBlue: #254272;
    --baseBlue: #386099;
    --brightBlue: #4681de;
    --mediumBlue: #a7c5f5;
    --paleBlue: #dbe6f4;
    --aqua: #52b4ad;
    --mint: #76bfba;
    --purple: #815be8;
    --rose: #d55f9a;
    --yellow: #d6ac3a;
    --lime: #9bbc2e;
    --slate: #7d9395;
    --orange: #db7823;
    --darkOrange: #d96038;
    --mediumOrange: #f59e81;
    --paleOrange: #faebde;
    --darkGray: #4d4d4d;
    --gray: #939598;
    --lightGray: #c4c4c4;
    --lightestGray: #e6e6e6;
    --black: #2e2e2e;
    --white: #fff;
    --untracked: #386099;
    --dirtyOlive: #999966;

    
    --blue4:#5D95D2;
    --blue5:#6DAAC6;
    --blue6: #5F60C0;
    --blue7: #3F70BB;
    --blue8: #4580DE;
    --aqua2:#77BFB9;
    --medGreen: #91BD7A;
    --chartreuse: #B9B534;
    --yellow2: #D5AC39;
   --orange2: #D9932E;

   

    --contentWidth: 1440px;
  }
 
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    font-size: 1.25rem;
    line-height: 1.4;
    color: var(--darkGray);
    ${Mq.sm} {
      font-size: 1.1rem;
    }
    *:focus {
      outline: 2px solid var(--mediumBlue);
      //border-radius: 5px;
    }
   
  }
  //overrides for region select
  .MuiOutlinedInput-root {
    background-color: var(--brightBlue);
    color: white !important;
    height: 2.75rem;
    font-size: 1rem !important;

  }
  .MuiPopover-root {
   .MuiPaper-root {
     max-height: 17rem;
     width: 425px;
    ${Mq.sm} {
           width: 350px;
         }
   }
  }
 
  .MuiList-root {
    background-color: var(--brightBlue);
    color: white;
    
  }
  .MuiMenuItem-root {
    justify-content: flex-start !important;
    &:hover {
        opacity: .4;
        background-color: var(--darkBlue) !important;
      }
    }
   
  
  .MuiButton-root {
    width: 100% !important;
    white-space: nowrap;
    justify-content: space-between !important;
    text-transform: none !important;
    background-color: var(--brightBlue) !important;
    color: white !important;
    font-size: 16.25px !important;
    letter-spacing: 0.00938em !important;
   
  }
  .MuiListSubheader-root {
    background-color: var(--brightBlue) !important;
    color: white !important;
    font-size: 1rem !important;
    margin-top: 2px;
  }
  //overrides for country autocomplete
  // .MuiAutocomplete-listbox {
  //   background: var(--baseBlue);
  //   color: var(--white);
  //   max-height: 10rem !important;
  //  > li {
  //     &:hover {
  //       background-color: var(--brightBlue) !important;
  //     }
  //   }
   
  // }
  
`
export default GlobalStyles
