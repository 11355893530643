exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/Country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-ig-1-1-js": () => import("./../../../src/templates/Ig1-1.js" /* webpackChunkName: "component---src-templates-ig-1-1-js" */),
  "component---src-templates-ig-1-2-js": () => import("./../../../src/templates/Ig1-2.js" /* webpackChunkName: "component---src-templates-ig-1-2-js" */),
  "component---src-templates-ig-1-3-js": () => import("./../../../src/templates/Ig1-3.js" /* webpackChunkName: "component---src-templates-ig-1-3-js" */),
  "component---src-templates-ig-2-1-js": () => import("./../../../src/templates/Ig2-1.js" /* webpackChunkName: "component---src-templates-ig-2-1-js" */),
  "component---src-templates-ig-2-2-js": () => import("./../../../src/templates/Ig2-2.js" /* webpackChunkName: "component---src-templates-ig-2-2-js" */),
  "component---src-templates-ig-3-1-js": () => import("./../../../src/templates/Ig3-1.js" /* webpackChunkName: "component---src-templates-ig-3-1-js" */),
  "component---src-templates-ig-3-2-js": () => import("./../../../src/templates/Ig3-2.js" /* webpackChunkName: "component---src-templates-ig-3-2-js" */),
  "component---src-templates-sp-1-2-js": () => import("./../../../src/templates/Sp1-2.js" /* webpackChunkName: "component---src-templates-sp-1-2-js" */),
  "component---src-templates-sp-1-6-js": () => import("./../../../src/templates/Sp1-6.js" /* webpackChunkName: "component---src-templates-sp-1-6-js" */),
  "component---src-templates-sp-2-1-js": () => import("./../../../src/templates/Sp2-1.js" /* webpackChunkName: "component---src-templates-sp-2-1-js" */),
  "component---src-templates-sp-2-2-js": () => import("./../../../src/templates/Sp2-2.js" /* webpackChunkName: "component---src-templates-sp-2-2-js" */),
  "component---src-templates-sp-3-2-js": () => import("./../../../src/templates/Sp3-2.js" /* webpackChunkName: "component---src-templates-sp-3-2-js" */),
  "component---src-templates-sp-4-1-js": () => import("./../../../src/templates/Sp4-1.js" /* webpackChunkName: "component---src-templates-sp-4-1-js" */),
  "component---src-templates-sp-5-1-js": () => import("./../../../src/templates/Sp5-1.js" /* webpackChunkName: "component---src-templates-sp-5-1-js" */),
  "component---src-templates-sp-6-1-js": () => import("./../../../src/templates/Sp6-1.js" /* webpackChunkName: "component---src-templates-sp-6-1-js" */),
  "component---src-templates-sp-6-2-js": () => import("./../../../src/templates/Sp6-2.js" /* webpackChunkName: "component---src-templates-sp-6-2-js" */),
  "component---src-templates-sp-6-3-js": () => import("./../../../src/templates/Sp6-3.js" /* webpackChunkName: "component---src-templates-sp-6-3-js" */),
  "component---src-templates-sp-7-1-js": () => import("./../../../src/templates/Sp7-1.js" /* webpackChunkName: "component---src-templates-sp-7-1-js" */)
}

