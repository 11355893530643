import React from 'react'
import { Helmet } from 'react-helmet'
import { useSiteMetadata } from '../hooks/useSiteMetaData'

const PageMeta = () => {
  const { title, description, siteUrl } = useSiteMetadata()
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={`${siteUrl}/social/social-card.png`} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={siteUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content={`${siteUrl}/social/social-card.png`}
      />
      <meta
        name="twitter:image:alt"
        content="line chart showing the breadth of vaccine coverage globally"
      />

      <html lang="en" />
    </Helmet>
  )
}
export default PageMeta
