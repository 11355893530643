const Xs = '500px'
const Sm = '740px'
const Md = '900px'
const Lg = '1080px'
const Xl = '1200px'
const Xxl = '1540px'

const breakpoints = {
  xs: Xs,
  sm: Sm,
  md: Md,
  lg: Lg,
  xl: Xl,
  xxl: Xxl
}

export const MediaQueries = Object.keys(breakpoints).reduce((acc, breakKey) => {
  return {
    ...acc,
    [breakKey]: `@media (max-width: ${breakpoints[breakKey]})`
  }
}, {})
